<template>
    <main class="main-page"  id="">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between align-items-center">
                        <div  class="col " >
                            <div class=" text-2xl text-primary font-bold" >
                                Daftar Order Item
                            </div>
                        </div>
                        <div  class="col-12 md:col-5 lg:col-4 comp-grid" >
                            <div class="">
                                <div class="mt-2">
                                    <Calendar :showIcon="true" :manualInput="false" :showButtonBar="true" dateFormat="yy-mm-dd" hourFormat="24" class="w-full" selectionMode="range" v-model="filters.pembayaran_tanggal_order.value" placeholder="Rentang Tanggal Order"    />
                                </div>
                            </div>
                        </div>
                        <div  class="col-12 md:col-5 lg:col-4 " >
                            <span class="p-input-icon-left w-full">
                            <i class="pi pi-search" />
                            <InputText  placeholder="Search" class="w-full" :value="searchText" @input="debounce(() => { searchText = $event.target.value })"  />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="flex align-items-center justify-content-around">
                            <div v-if="searchText" :class="filterTagClass">
                                Search
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                            </div>
                            <div v-if="filterHasValue(filters.pembayaran_tanggal_order)" :class="filterTagClass">
                                Tanggal Order
                                <Chip class="font-medium px-2 py-1" removable @remove="removeFilter(filters.pembayaran_tanggal_order)">
                                {{ getFilterLabel(filters.pembayaran_tanggal_order) }}
                                </Chip>
                            </div>
                        </div>
                        <div >
                            <template v-if="showBreadcrumbs && $route.query.tag && !isSubPage">
                                <Breadcrumb :home="{icon: 'pi pi-home', to: '/pembayaran'}" :model="pageBreadCrumb" />
                            </template>
                            <!-- page records template -->
                            <div class="page-records"  >
                                <DataTable :lazy="true"   :loading="loading" editMode="cell" @cell-edit-complete="onCellEditComplete"   :value="records" dataKey="id" @sort="onSort($event)" class="editable-cells-table" :showGridlines="false" :rowHover="true" responsiveLayout="stack">
                                    <Column  field="validasi" header="Validasi" >
                                        <template #body="{data}">
                                            <span class="table-inlined-edit">{{ data.validasi }}</span>
                                        </template>
                                        <template #editor="{ data, field }">
                                            <api-data-source   api-path="components_data/validasi_option_list" >
                                                <template v-slot="req">
                                                    <Dropdown  class="w-full"    :loading="req.loading"   optionLabel="label" optionValue="value"   v-model="data[field]" :options="req.response" label=""  placeholder="" >
                                                    </Dropdown> 
                                                </template>
                                            </api-data-source>
                                        </template>
                                    </Column>
                                    <Column  field="no_invoice" header="No Invoice" >
                                        <template #body="{data}">
                                            {{ data.no_invoice }}
                                        </template>
                                    </Column>
                                    <Column  field="tanggal_order" header="Tanggal Order" >
                                        <template #body="{data}">
                                            {{ data.tanggal_order }}
                                        </template>
                                    </Column>
                                    <Column  field="username_id" header="Username ID" >
                                        <template #body="{data}">
                                            {{ data.username_id }}
                                        </template>
                                    </Column>
                                    <Column  field="alamat_kirim" header="Alamat" >
                                        <template #body="{data}">
                                            <Button class="p-button-text" icon="pi pi-eye" label="Lihat" v-if="data.alamat_kirim" @click="app.openPageDialog({ page: 'alamat_user/list_admin', url: `/alamat_user/list_admin/alamat_user.user_id/${data.alamat_kirim}` , closeBtn: true, width: '50%' })" />
                                        </template>
                                    </Column>
                                    <Column  field="kode_transaksi" header="Daftar Order" >
                                        <template #body="{data}">
                                            <Button class="p-button-text" icon="pi pi-eye" label="Lihat" v-if="data.kode_transaksi" @click="app.openPageDialog({ page: 'keranjang/list', url: `/keranjang/index/keranjang.kode_transaksi/${data.kode_transaksi}` , closeBtn: true, width: '90%' })" />
                                        </template>
                                    </Column>
                                    <Column  field="status_barang" header="Status Barang" >
                                        <template #header>
                                            <Avatar icon="pi pi-user-edit" class="mr-2" />
                                            </template>
                                            <template #body="{data}">
                                                <span class="table-inlined-edit">{{ data.status_barang }}</span>
                                            </template>
                                            <template #editor="{ data, field }">
                                                <api-data-source   api-path="components_data/status_barang_option_list" >
                                                    <template v-slot="req">
                                                        <Dropdown  class="w-full"    :loading="req.loading"   optionLabel="label" optionValue="value"   v-model="data[field]" :options="req.response" label=""  placeholder="" >
                                                        </Dropdown> 
                                                    </template>
                                                </api-data-source>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                                <!-- page loading indicator -->
                                <template v-if="loading">
                                </template>
                                <!-- end of page loading indicator-->
                                <!-- Empty record -->
                                <template v-if="pageReady && !records.length">
                                    <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                        Data Tidak Ditemukan
                                    </div>
                                </template>
                                <!-- end of empty record-->
                                <!-- pagination component-->
                                <template v-if="showFooter && pageReady">
                                    <div class="flex justify-content-between align-items-center">
                                        <div class="flex justify-content-center flex-grow-0">
                                        </div>
                                        <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                            <Paginator class="border-none bg-transparent py-3" :first="recordsPosition - 1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
                                                <template #start>
                                                    <span class="text-sm text-gray-500 px-2">
                                                    Records <b>{{ recordsPosition }} of {{ totalRecords }}</b>
                                                    </span>
                                                </template>
                                                <template #end>
                                                </template>
                                            </Paginator>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of pagination component-->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { ApiService } from 'src/services/api';
	import { usePageStore } from 'src/store/page';
	import { useApp } from 'src/composables/app.js';
	import { useListPage } from 'src/composables/listpage.js';
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'PEMBAYARAN-LIST_ADMIN',
		},
		pageName: {
			type: String,
			default : 'pembayaran',
		},
		routeName: {
			type: String,
			default: 'pembayaranlist_admin',
		},
		apiPath: {
			type: String,
			default: 'pembayaran/list_admin',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: false,
		},
		emptyRecordMsg: {
			type: String,
			default: "Data Tidak Ditemukan",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Yakin ingin menghapus data ini?",
		},
		msgAfterDelete: {
			type: String,
			default: "Data berhasil dihapus",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	
	const defaultStoreState = {
		filters: {
			pembayaran_tanggal_order: {
				value: [],
				valueType: 'range-date',
				options: [],
			}
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const { pageReady, loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,   removeFilter, getFilterLabel, filterHasValue,  } = page.methods;
	
	function getActionMenuModel(data){
		return []
	}
	function onCellEditComplete(event) {
		let { data, newValue, field } = event;
		if (newValue){
			data[field] = newValue;
			let payload = {}
			let url = `pembayaran/edit/${data.id}`;
			payload[field] =  newValue;
			ApiService.post(url, payload).then((response) => {
				//field value saved	
			}).catch(err => {
				console.error(err);
			});
		}
		else{
			event.preventDefault();
		}
	}
	
	onMounted(()=>{ 
		const pageTitle = "Pembayaran";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
